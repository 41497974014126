<script>
import { mapState, mapActions, mapGetters } from "vuex";
import editor from "./editor";
export default {
  components: { editor },
  data() {
    return {
      filterText: "",
      actualFilterVal: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      selectNodeId: ''
    };
  },
  watch: {
    actualFilterVal(val) {
      this.$refs.tree.filter(val);
    },
    directiveTreeDatas(val) {
      this.$nextTick(() => {
        const { company_id, public_type_id } = this.$route.query;
        if (company_id) {
          this.$refs.tree.setCurrentKey(company_id);
          this.$emit('nodeSelect', Object.assign({}, 
            { company_id: company_id, public_type_id: '' }
          ));
        }
        if (public_type_id) {
          this.$refs.tree.setCurrentKey(public_type_id);
          this.$emit('nodeSelect', Object.assign({}, 
            { company_id: '', public_type_id: public_type_id }
          ));
        }
      })
    }
  },
  created() {
    this.getLeftTreeDatas();
  },
  computed: {
    ...mapState([
      'loginAccountInfo'
    ]),
    ...mapGetters(["directiveTreeDatas"]),
    isManage() {
      return !!this.loginAccountInfo.is_manage;
    }
  },
  methods: {
    ...mapActions([
      "getDirectiveTree",
      "deleteTreeData"
    ]),
    async getLeftTreeDatas() {
      try {
        this.$changeLoadingState(true);
        await this.getDirectiveTree();
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterChange(val) {
      this.actualFilterVal = val;
    },
    addClick() {
      this.$refs.singleEditor.show();
    },
    saveSuccess() {
      this.getLeftTreeDatas();
    },
    editData(node, data) {
      let parent_id = [];
      if (node.level === 2) {
        parent_id.push(data.parent_id);
      } else if (node.level === 3) {
        parent_id.push(node.parent.parent.data.id);
        parent_id.push(data.parent_id);
      }
      this.$refs.singleEditor.show({
        id: data.id,
        parent_id: parent_id,
        name: data.name,
        sort: data.sort
      });
    },
    async deleteData(data) {
      const ret = await this.deleteTreeData({
        ids: [data.id]
      });
      if (ret && ret.status === 'success') {
        this.getLeftTreeDatas();
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    nodeClick(data, node) {
      if (this.selectNodeId === data.id) {
        return;
      }
      this.selectNodeId = data.id;
      this.$emit('nodeSelect', Object.assign({}, 
        node.level === 2 ? { company_id: data.id, public_type_id: '' } : {},
        node.level === 3 ? { company_id: '', public_type_id: data.id } : {}
      ));
    }
  },
};
</script>
<template>
  <div class="left-container">
    <el-input
      size="mini"
      placeholder="请输入关键字"
      v-model="filterText"
      @change="filterChange"
    >
      <el-button slot="append" type="primary">查询</el-button>
    </el-input>
    <el-button
      v-if="isManage"
      type="primary"
      size="mini"
      style="margin: 15px 0px"
      @click="addClick"
    >
      添加
    </el-button>
    <div class="tree-container" :style="{height: isManage ? 'calc(100% - 86px)' : 'calc(100% - 28px)' }">
      <el-tree
        class="directive-tree"
        :data="directiveTreeDatas"
        :props="defaultProps"
        default-expand-all
        node-key="id"
        :filter-node-method="filterNode"
        ref="tree"
        @node-click="nodeClick"
        :expand-on-click-node="false"
        :highlight-current="true"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span v-if="node.level !== 1 && isManage">
            <el-button
              type="text"
              size="mini"
              icon="el-icon-edit"
              @click="editData(node, data)"
            >
            </el-button>
            <el-popconfirm
              title="确定要删除当前节点吗？"
              @confirm="deleteData(data)"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                icon="el-icon-delete"
                :disabled="!(node.level === 3 || !data.children || data.children.length === 0)"
              >
              </el-button>
            </el-popconfirm>
          </span>
        </span>
      </el-tree>
    </div>
    <editor ref="singleEditor" @confirm="saveSuccess"></editor>
  </div>
</template>
<style lang="scss" scoped>
.left-container {
  background: #fff;
  height: 100%;
  padding: 10px;
  position: relative;
  .tree-container {
    height: calc(100% - 28px);
    overflow: auto;
  }
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>
