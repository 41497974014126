<script>
export default {
    props: ['isManage'],
    data() {
        return {
            form: {
                instruction_name: '',
                status: ''
            }
        };
    },
    methods: {
        onSubmit() {
            this.$emit('search');
        },
        getValue() {
            const val = Object.assign({}, this.form,
                !this.isManage ? { status: 'true' } : {}
            );
            return val;
        }
    }
};
</script>
<template>
    <el-form :inline="true" size="mini" :model="form" class="demo-form-inline">
        <el-form-item label="指令名称">
            <el-input v-model="form.instruction_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" v-if="isManage">
            <el-select v-model="form.status" clearable placeholder="请选择">
            <el-option label="是" value="true"></el-option>
            <el-option label="否" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
    </el-form>
</template>
