<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { dateTransform } from "@/utils/utils.js";

export default {
  props: ['pageNum', 'oldParams'],
  data() {
    return {
      tableColumns: [
        {
          label: "指令名称",
          prop: "instruction_name",
        },
        {
          label: "指令码",
          prop: "instruction_code",
        },
        {
          label: "编码格式",
          prop: "unicode",
        },
        {
          label: "协议类型",
          prop: "send_type",
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: 130
        },
        {
          label: "是否启用",
          prop: "status",
        },
        {
          label: "加入回车/空格",
          prop: "enter_space",
          width: 120
        },
        {
          label: "备注",
          prop: "remark",
        },
        {
          label: "操作",
          prop: "operations",
          width: "160px"
        },
      ],
      formatMap: {
        // send_type: {
        //   '1': 'tcpip',
        //   '2': 'udp'
        // },
        unicode: {
          '1': '字符串',
          '2': '16进制'
        },
        enter_space: {
          '0': '否',
          '1': '回车',
          '2': '空格',
          '3': '回车/换行',
          '4': '换行/回车'
        },
        status: {
          'true': '是',
          'false': '否'
        }
      }
    };
  },
  computed: {
    ...mapState([
      'loginAccountInfo'
    ]),
    ...mapGetters(["directiveTableDatas", "directiveTableListTotal"]),
    formatTime() {
      return (val) => {
        return dateTransform(val) || "";
      };
    },
    tableHeight() {
      let h = document.body.clientHeight - 60 - 20 - 20 - 47 - 32 - 15 - 21;
      h = h - 28 - 15; 
      return h;
    },
    isManage() {
      return !!this.loginAccountInfo.is_manage;
    },
    columns() {
      const currentColumns = this.tableColumns.concat([]);
      if (!this.isManage) {
        currentColumns.splice(5, 1);
      }
      return currentColumns;
    }
  },
  methods: {
    ...mapActions(['deleteTableData']),
    handleSelectionChange(val) {
      this.$emit("selectChange", val);
    },
    getTableData(params) {
      this.$emit("getDatas", params);
    },
    getFormatName(key, val) {
      return this.formatMap[key][val] || '';
    },
    handleEdit(row) {
      let url = '/publicResource/publicDirective/edit?id=' + row.id;
      if (this.oldParams.company_id) {
        url += '&company_id=' + this.oldParams.company_id;
      }
      if (this.oldParams.public_type_id) {
        url += '&public_type_id=' + this.oldParams.public_type_id;
      }
      this.$router.push(url);
    },
    handleView(row) {
      let url = '/publicResource/publicDirective/view?id=' + row.id;
      if (this.oldParams.company_id) {
        url += '&company_id=' + this.oldParams.company_id;
      }
      if (this.oldParams.public_type_id) {
        url += '&public_type_id=' + this.oldParams.public_type_id;
      }
      this.$router.push(url);
    },
    async deleteData(row) {
      try {
        this.$changeLoadingState(true);
        await this.deleteTableData({ids: [row.id]});
        this.$changeLoadingState(false);
        this.getTableData();
      } catch (error) {
        console.log(error);
        this.$changeLoadingState(false);
      }
    }
  },
  components: {
    Pagination,
  },
};
</script>
<template>
  <div>
    <el-table
      :data="directiveTableDatas"
      style="width: 100%"
      class="projectsTable"
      border
      :height="tableHeight"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="c in columns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ formatTime(scope.row[c.prop]) }}
          </div>
          <div v-else-if="!!formatMap[c.prop]">
            {{ getFormatName(c.prop, scope.row[c.prop]) }}
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button
              v-if="!isManage"
              size="mini"
              type="primary"
              @click="handleView(scope.row)"
            >
              查看
            </el-button>
            <el-button
              v-if="isManage"
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
            >
              编辑
            </el-button>
            <el-popconfirm
              v-if="isManage"
              title="确定要删除当前数据吗？"
              @confirm="deleteData(scope.row)"
            >
              <el-button
                slot="reference"
                type="danger"
                size="mini"
                style="margin-left: 10px;"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
          <span v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="directiveTableListTotal > 0"
      :handleSizeChange="
        (v) => {
          this.getTableData({ page_size: v, page_num: 1 });
        }
      "
      :handleCurrentPageChange="
        (v) => {
          this.getTableData({ page_num: v });
        }
      "
      :total="directiveTableListTotal"
      :pageNum="pageNum"
    />
  </div>
</template>